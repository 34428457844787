import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import './styles/common.css'

import router from './router.js'
import store from './store'
import './permission' 


createApp(App)
.use(store)
.use(router)
.use(Antd)
.mount('#app')