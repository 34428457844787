import request from '../request'

//http://192.168.6.113:8003/Home/item/show?page_id=484&item_id=10

export function getToken(params) {
  return request({
    url: '/token',
    method: 'post',
    data: params
  })
}
//展馆列表
export function getCategory(params) {
  return request({
    url: '/showroom/category',
    method: 'post',
    data: params
  })
}
//展位列表
export function getLists(params) {
  return request({
    url: '/showroom/list',
    method: 'post',
    data: params
  })
}


//新建订单
export function createOrder(params) {
  return request({
    url: '/order/create',
    method: 'post',
    data: params
  })
}

//更新订单
export function updateOrder(params) {
  return request({
    url: '/order/update',
    method: 'post',
    data:params
  })
}


//新增展位
export function createRoom(params) {
  return request({
    url: '/showroom/create',
    method: 'post',
    data:params
  })
}
//编辑展位
export function updateRoom(params) {
  return request({
    url: '/showroom/update',
    method: 'post',
    data:params
  })
}

//删除展位
export function removeRoom(params) {
  return request({
    url: '/showroom/delete',
    method: 'post',
    data:params
  })
}


//获取销售人员
export function getSaleList(params) {
  return request({
    url: '/member/sale/list',
    method: 'post',
    data:params
  })
}


// 日志列表
export function getMessage(params) {
  return request({
    url: '/member/message',
    method: 'post',
    data:params
  })
}
//新增日志
export function createMessage(params) {
  return request({
    url: '/member/message/create',
    method: 'post',
    data:params
  })
}


// 创建标尺
export function createRuler(params) {
  return request({
    url: '/showroom/ruler/create',
    method: 'post',
    data:params
  })
}

// 获取标尺
export function getRuler(params) {
  return request({
    url: '/showroom/ruler',
    method: 'post',
    data:params
  })
}

// 删除标尺  馆id category_id  标尺id showroom_ruler_id
export function removeRuler(params) {
  return request({
    url: '/showroom/ruler/delete',
    method: 'post',
    data:params
  })
}

//复制展馆
export function copyCategory(params) {
  return request({
    url: '/showroom/category/copy',
    method: 'post',
    data:params
  })
}

//删除展馆
export function removeCategory(params) {
  return request({
    url: '/showroom/category/delete',
    method: 'post',
    data:params
  })
}