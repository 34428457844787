import {getLists,getToken,getRuler} from '../api/index.js';
// import { Loading } from 'element-ui';

export default{
  getToken(context){
   
  },
  getLists(context,params){
    return new Promise((resolve,reject)=>{
      let token = localStorage.getItem('mtoken');
      context.commit('setLists',[]); 
      getLists({'token':token,'category_id':params}).then(res=>{
        // setTimeout(() => {
          context.commit('setLists',res); 
          resolve();
        // }, 200);
      })
    })
  },
  getLineLists(context,params){
    return new Promise((resolve,reject)=>{
      let token = localStorage.getItem('mtoken');
      context.commit('setLineLists',[]); 
      getRuler({'token':token,'category_id':params}).then(res=>{
          context.commit('setLineLists',res); 
          resolve();
      })
    })
  },
  login(context,params){
    return new Promise((resolve,reject)=>{
      getToken(params).then(res=>{
        console.log(res);
        if(res.errcode===0){
          let token = res.token;
          localStorage.token = token;
          context.commit('setToken',token)
          resolve(res);
        }else{
          console.log('reject');
          reject(res);
        }

      })
    })
  }
}