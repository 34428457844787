<template>
  <div>
    <a-config-provider :locale="locale">
      <router-view v-slot="{ Component }">
        <div>
            <transition name="fade-transform" mode="out-in">
              <component :is="Component" />
            </transition>
          </div>
      </router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
// import HelloWorld from './components/HelloWorld.vue'

// This starter template is using Vue 3 experimental <script setup> SFCs
// Check out https://github.com/vuejs/rfcs/blob/script-setup-2/active-rfcs/0000-script-setup.md
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
    };
  }
}
</script>

<style>
</style>