let user = localStorage.user; 

console.log(!!user&&JSON.parse(localStorage.user) || {});


export default {
  user: !!user&&JSON.parse(localStorage.user) || {},
  token:localStorage.mtoken,
  category:[],
  selectCategory: {},
  selectIndex:localStorage.categoryIndex*1||0, //选中第几个馆
  category_id:localStorage.categoryId || 1,
  category_value:'',
  lists:[], //展馆展位列表
  lineLists:[], //展馆标尺列表
  tempLists:[], //绘制的展位列表（未上传之前的）
  drawTempLists:[], //新增，还未绘制的矩形
  datas:[],
  rulerIsShow:true,
  guidWidth:1000,
  drawType:'rect', //绘制类型，画展位或者标尺， rect，line
  selectGroup:null, //当前操作的canvas组
  setting:{

  },
  buyPopupIsShow:false, //购买&锁定展位弹出层
  buyPopupData:null, // 当前编辑展位的信息
  addPopupIsShow:false, //绘制新增展位弹出层
  addPopupData:null,
  editPopupIsShow:false, //绘制新增展位弹出层
  editPopupData:null,
  cropPopupIsShow:false, //裁剪展位
  cropPopupData:null, // 裁剪展位信息
}